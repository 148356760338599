import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const OperationsSubMenu = () => (
  <Wrapper className="operation-submenu">
    <FlexWrapper>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/en/elbow-surgery/">
            Elbow surgery
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/en/elbow-surgery/biceps-tendon-rupture/"
            >
              Biceps tendon rupture
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/en/elbow-surgery/epicondylitis/"
            >
              Epicondylitis : Elbow tendonitis
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/elbow-surgery/ulnar-nerve-entrapment/"
            >
              Ulnar nerve entrapment
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/en/shoulder-surgery/">
            Shoulder surgery
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/en/shoulder-surgery/acromio-clavicular-arthrosis/"
            >
              Acromio-clavicular arthrosis
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/shoulder-surgery/fracture-of-the-clavicle/"
            >
              Fracture of the clavicle
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/shoulder-surgery/shoulder-luxation/"
            >
              Luxation of the shoulder
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/shoulder-surgery/rupture-rotator-cuff-tendons/"
            >
              Rupture of the rotator cuff tendons
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/shoulder-surgery/sub-acromial-conflict/"
            >
              Sub-acromial conflict
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/shoulder-surgery/tendinopathy-rotator-cuff/"
            >
              Tendinopathy of the rotator cuff
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/en/wrist-surgery/">
            Wrist surgery
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/en/wrist-surgery/arthroscopy-of-the-wrist/"
            >
              Arthroscopy of the wrist
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/wrist-surgery/de-quervain-syndrom/"
            >
              De Quervain syndrome
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/en/hand-surgery/synovial-cyst/">
              Synovial Cyst
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/en/hand-surgery/">
            Hand surgery
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/en/nerves-surgery/carpal-tunnel-syndrome/"
            >
              Carpal tunnel syndrome
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/hand-surgery/dupuytren-s-contracture/"
            >
              Dupuytren's contracture
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/en/hand-surgery/hand-emergency/"
            >
              Hand emergency
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/en/hand-surgery/hand-wounds/">
              Hand wounds
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/hand-surgery/mucous-cysts-fingers/"
            >
              Mucous cysts
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/en/hand-surgery/rhizarthrosis/">
              Rhizarthrosis: Thumb basal joint osteoarthritis
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/en/hand-surgery/trigger-finger/
            "
            >
              Trigger finger
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/en/technics/">
            Technics performed
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link activeClassName="active" to="/en/technics/arthroscopy/">
              Arthroscopy
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/en/technics/microsurgery/">
              Microsurgery
            </Link>
          </Li>
        </Ul>
        <Cat
          css={css`
            padding-top: 0.1em;
          `}
        >
          <Link activeClassName="active" to="/en/sports-surgery/">
            Sports surgery
          </Link>
        </Cat>
        <Cat
          css={css`
            padding-top: 0.3em;
          `}
        >
          <Link activeClassName="active" to="/en/nerves-surgery/">
            Nerves surgery
          </Link>
        </Cat>
      </FlexItem>
    </FlexWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  position: absolute;
  top: 84px;
  left: 0;
  padding: 0 10px;
  background-color: white;
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.0975);
  box-shadow: rgba(0, 0, 0, 0.15) 12px 12px 10px -10px;
  @media (max-width: 1050px) {
    z-index: 3;
    position: fixed;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0 15px;
  z-index: 30;
  @media (max-width: 1050px) {
    display: grid;
    padding: 0px 12px 15px;
    box-sizing: border-box;
  }
`

const FlexItem = styled.div`
  width: 20%;
  display: inline-block;
  displey: flex;
  flex-direction: column;
  padding: 0 8px;
  :first-of-type {
    padding-left: 0px;
  }
  :last-of-type {
    padding-right: 0px;
  }
  @media (max-width: 1050px) {
    width: 100%;
    padding: 0;
  }
`

const Cat = styled.div`
  font-size: 1.14em;
  letter-spacing: 0.025em;
  font-weight: 600;
  width: 100%;
  padding-bottom: 0.5em;
  font-family: Raleway, "Times new roman", serif;
  a {
    text-decoration: none;
  }
  :hover {
    a {
      color: #ca9d63;
    }
  }
  @media (max-width: 1050px) {
    margin: 10px 0 2px;
    padding-bottom: 0;
  }
  @media (max-width: 678px) {
    margin: 6px 0 0;
  }
`

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  @media (max-width: 1050px) {
    display: inline;
  }
`

const Li = styled.li`
  font-size: 0.9em;
  line-height: 1.55em;
  padding-bottom: 0.6em;
  letter-spacing: 0.01em;
  a {
    text-decoration: none;
    padding: 0.3em 0;
    display: block;
    transition: all 0.15s ease-in-out;
  }
  :hover {
    a {
      color: #ca9d63;
      transition: all 0.15s ease-in-out;
    }
  }
  @media (max-width: 1050px) {
    display: inline;
    a {
      display: inline;
      ::after {
        content: "•";
        margin: 0 7px;
        color: #333;
      }
    }
    :last-of-type {
      a {
        ::after {
          content: "";
        }
      }
    }
  }
  @media (max-width: 678px) {
    font-size: 0.88em;
  }
`

export default OperationsSubMenu
