import React from "react"
import Header from "./header-en"
import Footer from "./footer-en"
import PopUpRdv from "./popUpRdv-en"
import "./layout.css"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"

const Layout = ({ children, ...props }) => (
  <div>
    <Helmet>
      <script type="application/ld+json">{`
{
  "@context": "http://schema.org",
  "@type": "Person",
  "name": "Dr Philippe Roure",
  "url": "https://www.docteurphilipperoure.com/",
  "sameAs": [
  "https://www.facebook.com/drphilipperoure/",
  "https://youtube.com/@drphilipperoure"
]
}
`}</script>
      <script type="application/ld+json">{`
{
"@context": "http://schema.org",
"@type": "Organization",
"url": "https://www.docteurphilipperoure.com/",
"contactPoint": [
{ "@type": "ContactPoint",
  "telephone": "+33-1-53-59-88-09",
  "contactType": "reservations"
}
]
}
`}</script>
      <link rel="preconnect" href="https://www.docteurphilipperoure.com" />
      <meta property="og:locale" content="en_US" />
    </Helmet>
    <Header pathFr={props.pathFr} />
    <PopUpRdv />
    <Main>{children}</Main>

    <Footer pathFr={props.pathFr} />
  </div>
)

export default Layout

const Main = styled.main`
  width: 1200px;
  max-width: 100%;
  padding: 0 0px 50px;
  margin: 40px auto 0;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    max-width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 678px) {
    margin-top: 20px;
  }
`
